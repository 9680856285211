@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('../static/interface/fonts/Poppins-Regular.ttf');
}
@font-face {
    font-family: 'PoppinsBold';
    font-weight: 700;
    src: url('../static/interface/fonts/Poppins-Bold.ttf');
}

* {
    margin: 0;
}

.mobile-logo{
    width: 30%;
    height: 30%;
    margin-bottom: 0 !important;
}

.br{
    border-right: 1px solid #6D6D6D;
    padding-right: 30px;
    margin-right: 30px;
}

.t-justfy{
    text-align: justify;
}

.el_wrap_main{
    display: inline-flex;
    flex-direction: column;
}

.el_wrap{
    display: flex;
    flex-direction: row;
}

.mr-1{
    margin-right: 10px !important;
}

.mb-1{
    margin-bottom: 10px;
}

.mb-2{
    margin-bottom: 20px;
}

.pb-2{
    padding-bottom: 20px;
}

.blue{
    color: #0032BE;
}

.h-20{
    height: 20%;
}

.h-80{
    height: 80%;
}

.h-100{
    height: 100%;
}

.w-70{
    width: 70%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mc{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

html,
body,
p {
    font-family: "Poppins", Arial, sans-serif;
    font-size: 100%;
}
h1,
h2,
h3,
h4,
strong {
    font-family: "PoppinsBold", Arial, sans-serif;   
    font-weight: 700;
}


/* [Three.js Canvas] */
.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    width: 100vw;
    height: 100vh;
    background-color: black;
}
/* [/Three.js Canvas] */


/* [Joystick] */
.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.joystick .joystick--arrows {
    position: absolute;
    width: 100px;
    height: 100px;
    bottom: 28px;
    right: 28px;
}
.joystick-arrow {
    height: 12px;
    width: 12px;
    position: absolute;
    background-image: url('../static/interface/images/joystick-arrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    pointer-events: none;
}
.joystick-arrow.arrow--up {
    top: 2%;
    left: 50%;
    margin-left: -6px;
}
.joystick-arrow.arrow--down {
    bottom: 2%;
    left: 50%;
    margin-left: -6px;
    transform: rotateZ(180deg);
}
.joystick-arrow.arrow--left {
    top: 50%;
    left: 2%;
    margin-top: -6px;
    transform: rotateZ(270deg);
}
.joystick-arrow.arrow--right {
    top: 50%;
    right: 2%;
    margin-top: -6px;
    transform: rotateZ(90deg);
}

#joystick-wrapper-movement>div>div.back {
    box-shadow: 9.21449px 9.21449px 18.429px rgba(0, 0, 0, 0.3);
}
#joystick-wrapper-movement>div>div.front {
    box-shadow: 0px 0px 18.429px rgba(0, 0, 0, 0.5);
}
/* [/Joystick] */


/* [Cockpit - HUD Buttons] */
.cockpit {
    position: fixed;
    display: inline-block;
    top: 70px;
    left: 60px;
}
.cockpit.cockpit--right {
    left: unset;
    right: 60px;
}
.cockpit ul {
    list-style-type: none;
    padding: 0;
}

.cockpit__item {
    height: 80px;
    width: 60px;
    background-color: #000000B2;
    margin-bottom: 10px;
    border: none;
}
.cockpit__item p {
    color: #ffffff;
    font-size: 0.84rem;
    width: inherit;
    text-align: center;
    cursor: default;
}

.cockpit div:first-child {
    margin-bottom: 18px;
    border-radius: 10px;
}
.cockpit li:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.cockpit li:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.cockpit__button {
    width: inherit;
    height: inherit;
    background: none;
    border: none;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}
.cockpit__button#button--books {
    background-image: url('../static/interface/images/books-icon.svg');
    cursor: default;
    height: 30px;
    margin: 14px 0 5px 0;
}
.cockpit__button#button--map {
    background-image: url('../static/interface/images/map-icon.svg');
}
.cockpit__button.button--audio--enabled {
    background-image: url('../static/interface/images/audio-icon.svg');
}
.cockpit__button.button--audio--disabled {
    background-image: url('../static/interface/images/no-audio-icon.svg');
}
.cockpit__button#button--share {
    background-image: url('../static/interface/images/share-icon.svg');
}
.cockpit__button#button--info {
    background-image: url('../static/interface/images/info-icon.svg');
}
/* [/Cockpit - HUD Buttons] */


/* [Video Modal] */
.video-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000046;
    display: none;
    pointer-events: none;
    z-index: 2;
}
.video-modal.video-modal--active {
    display: unset;
    pointer-events: unset;
}
.video-modal iframe {
    position: fixed;
    width: 95%;
    height: 95%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
}
/* [/Video Modal] */


/* [Informative Tag] */
.informative-tag {
    position: fixed;
    width: 300px;
    height: 110px;
    top: 70px;
    left: 50%;
    transform: translate(-50%, 0);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    pointer-events: none;
    opacity: 0;
    transition: 300ms opacity;
}
.informative-tag.informative-tag--active {
    opacity: 1;
}

.informative-tag--start {
    background-image: url('../static/interface/images/tag_start.svg');
}
.informative-tag--institutional {
    background-image: url('../static/interface/images/tag_institutional.svg');
}
.informative-tag--ionic {
    background-image: url('../static/interface/images/tag_ionic.svg');
}
.informative-tag--literature {
    background-image: url('../static/interface/images/tag_literature.svg');
}
.informative-tag--sde {
    background-image: url('../static/interface/images/tag_sde.svg');
}
.informative-tag--outside {
    background-image: url('../static/interface/images/tag_outside.svg');
}
/* [/Informative Tag] */


/* [Map Modal] */
.map-modal {
    max-width: 1200px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
    transition: 300ms opacity;
    z-index: 2;
}
.map-modal.map-modal--active {
    opacity: 1;
    pointer-events: all;
}

.map-modal__background {
    width: 60%;
    height: 100%;
    position: absolute;
    border-radius: 20px;
    right: 0;
    top: 0;
    z-index: -1;
}
.map-modal__background.background--institutional {
    background: linear-gradient(312.63deg, #0032BE 1.19%, #46B2FF 98.98%);
}
.map-modal__background.background--ionic {
    background: linear-gradient(312.63deg, #FF505F 1.19%, #FAA01E 98.98%);
}
.map-modal__background.background--literature {
    background: linear-gradient(312.63deg, #FF626F 1.19%, #0032BE 98.98%);
}
.map-modal__background.background--sde {
    background: linear-gradient(312.63deg, #46B2FF 1.19%, #9747FF 98.98%);
}

.map-modal__column--info {
    width: 45%;
    height: auto;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 0 0 0 60px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 100%, 0 89%, 14% 80%, 0 69%);
}
.map-modal__title {
    position: fixed;
    width: 320px;
    height: 120px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 48px;
    margin-left: 60px;
    z-index: 2;
}

.map-modal__column--info .map-modal-info__slogan {
    font-size: 2.4rem;
    margin-bottom: 48px;
    margin-top: 180px;
    line-height: 125%;
}
.map-modal__column--info p:not(.map-modal-info__link) {
    font-size: 1.1rem;
}
.map-modal__column--info .map-modal-info__link {
    font-size: 1.9rem;
    line-height: 36px;
    cursor: pointer;
}
#map-modal--ionic .map-modal-info__slogan {
    margin-bottom: 28px;
}

.map-modal__column--map {
    width: 55%;
}

.map-modal__map {
    width: 95%;
    height: 95%;
    margin-left: 2.5%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
}
.map-modal__map.map--institutional {
    background-image: url('../static/interface/images/maps/institutional-map.svg');
}
.map-modal__map.map--ionic {
    background-image: url('../static/interface/images/maps/ionic-map.svg');
}
.map-modal__map.map--literature {
    background-image: url('../static/interface/images/maps/literature-map.svg');
}
.map-modal__map.map--sde {
    background-image: url('../static/interface/images/maps/sde-map.svg');
}

.map-modal__big-arrow {
    position: fixed;
    background-image: url('../static/interface/images/big-arrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    height: 196px;
    width: 106px;
    bottom: 20px;
}

.map-modal__big-arrow.big-arrow--institutional {
    /* Link to generate filters: https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(57%) sepia(33%) saturate(2126%) hue-rotate(183deg) brightness(105%) contrast(102%);
}
.map-modal__big-arrow.big-arrow--ionic {
    filter: invert(14%) sepia(78%) saturate(2730%) hue-rotate(219deg) brightness(118%) contrast(121%);
}
.map-modal__big-arrow.big-arrow--literature {
    filter: invert(70%) sepia(100%) saturate(450%) hue-rotate(358deg) brightness(101%) contrast(105%);
}
.map-modal__big-arrow.big-arrow--sde {
    filter: invert(43%) sepia(80%) saturate(5834%) hue-rotate(251deg) brightness(102%) contrast(100%);
}

.map-modal__small-arrows-grid {
    position: fixed;
    height: 60px;
    width: 310px;
    bottom: 92px;
    left: 135px;
    display: grid;
    grid-template-columns: 18px 18px 18px 18px 18px 18px 18px 18px;
    grid-template-rows: 21px 21px;
    row-gap: 17px;
    column-gap: 24px;
}
.map-modal__small-arrows-grid>div {
    background-image: url('../static/interface/images/little-arrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
}
.small-arrows--institutional {
    filter: invert(58%) sepia(23%) saturate(6965%) hue-rotate(319deg) brightness(103%) contrast(101%);
}
.small-arrows--ionic {
    filter: invert(14%) sepia(78%) saturate(2730%) hue-rotate(219deg) brightness(118%) contrast(121%);
}
.small-arrows--literature {
    filter: invert(43%) sepia(80%) saturate(5834%) hue-rotate(251deg) brightness(102%) contrast(100%);
}
.small-arrows--sde {
    filter: invert(57%) sepia(33%) saturate(2126%) hue-rotate(183deg) brightness(105%) contrast(102%);
}
/* [/Map Modal] */


/* [Share Modal] */
.share-modal {
    max-width: 800px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    background-color: #ffffff;
    border-radius: 20px;
    pointer-events: none;
    transition: 300ms opacity;
    z-index: 2;
}
.share-modal.share-modal--active {
    opacity: 1;
    pointer-events: all;
}

.share-modal__banner {
    max-height: 278px;
    width: 100%;
    height: 100%;
    background-image: url('../static/interface/images/share-modal-banner.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.share-modal__info {
    height: auto;
    width: fit-content;
    margin: auto;
}
.share-modal__info .share-modal__title {
    width: 314px;
    height: 45px;
    background-image: url('../static/interface/images/splashscreen/ftd-metaverso.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    margin: auto;
    margin-top: 33px;
    margin-bottom: 36px;
}
.share-modal__info p {
    margin: auto;
    text-align: center;
}
.share-modal__icons-area {
    display: flex;
    width: fit-content;
    margin: auto;
    margin-top: 16px;
}

.share-modal__icons-area div:first-child {
    margin-right: 48px;
}
.share-modal__icons-area div:last-child {
    margin-left: 48px;
}
.share__icon {
    height: 34px;
    width: 34px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    cursor: pointer;
}
.share__icon#share__facebook {
    background-image: url('../static/interface/images/facebook-icon.svg');
}
.share__icon#share__twitter {
    background-image: url('../static/interface/images/twitter-icon.svg');
}
.share__icon#share__linkedin {
    background-image: url('../static/interface/images/linkedin-icon.svg');
}

.share-modal__link-area {
    width: fit-content;
    display: flex;
    margin: auto;
    margin-top: 40px;
}
.share-modal__link-area>div {
    display: flex;
}
.share-modal__link-area h3 {
    margin: auto;
}
.share-modal__arrow {
    height: 42px;
    width: 23px;
    background-image: url('../static/interface/images/big-arrow.svg');
    filter: invert(57%) sepia(33%) saturate(2126%) hue-rotate(183deg) brightness(105%) contrast(102%);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    margin-right: 30px;
}
.share-modal__copy-link {
    height: 28px;
    width: 110px;
    background-image: url('../static/interface/images/copy-link-button.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    margin: auto;
    margin-left: 41px;
    cursor: pointer;
}
.share-modal #share__link {
    width: 300px;
    font-family: "PoppinsBold";
    border: none;
    font-size: 1.2rem;
}
/* [/Share Modal] */


/* [Placeholder Exit Button] */
.exit-button {
    position: fixed;
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 50px;
    top: -10px;
    right: -10px;
    font-style: 'Poppins';
    font-size: 1.2rem;
    z-index: 2;
}
.exit-button.exit-button--lower {
    top: 10px;
    right: 10px;
}
/* [/Placeholder Exit Button] */


/* [Splashscreen Modal] */
.splashscreen {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: url('../static/interface/images/splashscreen/splashscreen-bg-preview.jpg') no-repeat center center;
    background-size: cover;
    z-index: 5;
    opacity: 0;
    transition: 300ms opacity;
    pointer-events: none;
}
.splashscreen.splashscreen--active {
    opacity: 1;
    pointer-events: unset;
}

.splashscreen__modal {
    max-width: 1200px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 300ms opacity;
    z-index: 10;
    border-radius: 20px;
    background: white;
    overflow: hidden;
}
.modal__content {
    position: relative;
    z-index: 2
}
.overlayer {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.1);
    z-index: 1;
    display: none;
}
.overlayer.overlayer--active {
    display: block;
}
.splashscreen__row {
    width: 100%;
    display: flex;
}
.splashscreen__column {
    width: 50%;
}
.splashscreen .column--left{
    width: 55%;
    overflow: hidden;
    position: relative;
    padding: 35px 70px;
}
.splashscreen .column--left .modal__content { 
    text-align: center;
}
.splashscreen .column--left .modal__content h1{ 
    margin-bottom: 3px;
} 
.splashscreen .column--left .modal__content h2{
    margin-bottom: 20px; 
}
.splashscreen .column--left .modal__content h3{ 
    font-size: 24px;
    line-height: 1.25em;
    margin-bottom: 10px; 
    padding: 0 30px;
}
.splashscreen .column--left .modal__content h3.smaller-title{
    font-size: 1.2rem;
    padding: 0 50px;
}
.splashscreen .column--right{
    width: 45%;
    background: url('../static/interface/images/splashscreen/120-anos-bg.jpg') no-repeat 0 0;
    background-size: cover;
}
.splashscreen .column--right-controls{
    width: 45%;
    background: url('../static/interface/images/AVATAR_RIG_01.png') no-repeat 0 0;
    background-size: cover;
}
.splashscreen .column--right-controls-mobile{
    height: 164px;
    width: 45%;
    background: url('../static/interface/images/AVATAR_RIG_02.png') no-repeat 0 0;
    background-size: cover;
}
.splashscreen .column--right-controls-tablet{
    height: 100%;
    width: 45%;
    background: url('../static/interface/images/AVATAR_RIG_01.png') no-repeat 0 0;
    background-size: cover;
}
.splashscreen .column--right .years120{
    padding-top: 40px;
}
.splashscreen .column--right .years120__top{ 
    position: relative;
    padding-left: 160px;
    color: white;
    max-width: 480px;
    margin-left: 30px;
    margin-bottom: 150px;
}
.splashscreen .column--right .years120__top h2{
    position: relative;
    margin-bottom: 20px;
    color: white;
}
.splashscreen .column--right .years120__top h2::after{
    margin: 7px 0;
    content: "";
    height: 3px;
    width: 100%;
    background: white;
    display: block;
} 
.splashscreen .column--right .years120__top p{
    font-size: 0.875em;
    padding-right: 10px;
}
.splashscreen .column--right .years120__top img{ 
    position: absolute;
    left: -10px;
    top: -20px;
}  
.splashscreen .column--right .years120__bottom{
    background: white;
    color: rgba(0, 50, 190, 1);
    text-align: center;
    padding: 80px 0 30px;
    position: relative;
}
.splashscreen .column--right .years120__bottom img{ 
    position: absolute;
    left: 50%;
    top: -115px;
    transform: translateX(-50%);
    max-width: 470px;
    height: auto;
}
.splashscreen .column--right .years120__bottom p{ 
    padding: 0 80px;
    line-height: 1.25em;
}
.splashscreen .column--right footer{
    text-align: center;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.splashscreen__controls {
    max-width: 530px;
    background: rgba(227, 227, 227, 1);
    border-radius: 20px;
    padding: 20px 20px 45px;
    color: rgba(48, 48, 48, 1);
    margin-top: 40px;
}
.splashscreen__controls p{ 
    font-size: .875em;
    text-align: left;
}
.splashscreen .column--left .splashscreen__controls h2{ 
    margin-bottom: 15px
} 
.splashscreen__controls img {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
}
.splashscreen__controls-mobile{
    display: none;
}
.splashscreen__controls-mobile p img{
    float: right;
    margin-left: 0;
    margin-top: -5px;
}
.splashscreen__loader { 
    margin-top: -55px;
}
.splashscreen__loader--ready {
    margin-top: -30px;
}
.splashscreen__loader--ready>img {
    max-width: 180px;
    height: auto;
    content: url('../static/interface/images/splashscreen/lets-go-button.svg');
    cursor: pointer;
}
.splashscreen__loader--ready>img:hover {
    filter: brightness(75%);
}
.splashscreen-video-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
}
.splashscreen-video-bg video{
    width: 165%;
    left: -10%;
    top: 0;
    position: absolute;
    z-index: 0;
}
.show-tablet,
.show-mobile{
    display: none;
}
#controls-modal .splashscreen__controls {
    padding: 20px 20px 10px;
}
/* [/Splashscreen Modal] */

/* [Webview Block Modal] */
#webview-block-modal {
    z-index: 6;
}
#webview-block-modal .splashscreen__modal {
    max-width: 1000px;
    max-height: 600px;
}

.splashscreen .column--right-webview {
    position: fixed;
    right: 80px;
    top: 28px;
    height: 550px;
    width: 200px;
    background: url('../static/interface/images/AVATAR_FULL_idle.png') no-repeat 0 0;
    background-size: cover;
}
.splashscreen .column--left-webview {
    width: 90%;
    position: relative;
    padding: 40px 175px 60px 80px;
}

.column--left-webview .webview-modal__title {
    width: 440px;
    height: 60px;
    background: url('../static/interface/images/splashscreen/ftd-metaverso.svg') no-repeat 0 0;
    background-size: cover;
    margin-bottom: 30px;
}

.webview-show-tablet-vertical,
.webview-show-mobile,
.webview-show-mobile-vertical,
.webview-show-small-mobile {
    display: none;
}

.column--left-webview .webview-modal__video {
    width: 790px;
    height: 270px;
    background-color: black;
    margin-bottom: 23px;
}
.column--left-webview h2 {
    font-size: 1.3rem;
}
.column--left-webview p {
    width: 390px;
    font-size: 0.85rem;
    margin: 10px 0 20px 0;
}

.column--left-webview .webview-modal__clip-area {
    display: flex;
}
.column--left-webview .webview-modal__clip-area>div {
    display: flex;
}
.column--left-webview #webview__link {
    width: 250px;
    height: 30px;
    font-family: "PoppinsBold";
    border: none;
    font-size: 1.35rem;
    color: #1A47C5;
}
.column--left-webview #webview-modal__copy-link {
    height: 30px;
    width: 178px;
    background: url('../static/interface/images/copy-link-button-mobile.svg') no-repeat center center;
    background-size: cover;
    margin: auto;
    margin: 0 43px 0 22px;
    cursor: pointer;
}
#webview-modal__copy-link.copied-link {
    background: url('../static/interface/images/copied-link-button-mobile.svg') no-repeat center center;
    background-size: cover;
}
.column--left-webview .webview-modal__small-arrows {
    height: 30px;
    width: 310px;
    background: url('../static/interface/images/webview-arrows.png') no-repeat center center;
    background-size: cover;
}
/* [/Webview Block Modal] */

/* [Gift Form] */
#gift__form form label {
    font-size: 1rem;
}
#gift__form form {
    margin-top: -10px;
}
.email_success {
    display: none;
    color: #9747FF;
    margin-top: 40px;
}
.email_success span.big-text {
    font-size: 1.5rem;
}
/* [/Gift Form] */

@media screen and (max-width: 1199px)
{
    /* [Splashscreen Modal] */
    .show-desktop {
        display: none;
    }
    /* [/Splashscreen Modal] */
}

@media screen and (min-width: 1200px)
{
    /* [Splashscreen Modal] */
    .show-desktop {
        display: block;
    }

    .wrap-controls{
        margin-right: 80px !important;
    }
    /* [/Splashscreen Modal] */
}

@media screen and (min-width: 641px) {
    .el_wrap_main{
        display: flex;
        flex-direction: row;
    }    
}

@media screen and (min-width: 641px) and (max-width: 1199px)
{
    /* [Splashscreen Modal] */
    .show-tablet{
        display: block;
    }

    .tablet-margin{
        position: relative;
        top: -15px;
        margin-top: 0 !important;
    }

    .control-icon img{
        width: 18%;
    }
    .control-icon img.ci_big{
        width: 12%;
    }

    .pb-t-0{
        padding-bottom: 0px !important;
    }

    .wrap-controls{
        margin-right: 20px !important;
    }

    .no-mb{
        margin-bottom: 0 !important;
    }
    .mb-2{
        margin-bottom: 5px;
    }

    .splashscreen .splashscreen__modal:not(.webview-modal) {
        max-width: 600px;
        max-height: 300px;
    }
    .splashscreen .column--left .modal__content h3.smaller-title{
        padding: 0 30px;
    }

    .table-text{
        font-size: 10px !important;
    }

    .fs-tablet {
        font-size: 8px !important;
    }

    .h-tablet-100{
      height: 100% !important;   
    }

    .w-tablet-60{
        width: 60% !important;
    }

    .no-max-height{
        max-height: none !important;
    }

    .splashscreen .column--left{
        padding: 20px;
    }
    .splashscreen .column--left .modal__content h3{
        font-size: 14px;
        padding: 0;
        margin-bottom: 10px;
    }
    .splashscreen .column--right .years120__top img {
        max-width: 90px;
        height: auto;
        left: -25px;
        top: -5px;
    }
    .splashscreen .column--right .years120__top{
        padding-left: 75px;
        margin-bottom: 60px;
    }
    .splashscreen .column--right .years120__top h2{
        font-size: 16px;
        margin-bottom: 10px;
    }
    .splashscreen .column--right .years120__top h2::after{
        margin: 0;
    }
    .splashscreen .column--right .years120__top p{
        font-size: 10px;
    }
    .splashscreen .column--left .splashscreen__controls h2{
        font-size: 14px;
        margin-bottom: 10px;
    }
    .splashscreen__controls {
        margin: 0;
        padding: 10px 15px 25px;
    }
    .splashscreen__controls p{
        font-size: 12px;
    }
    .splashscreen__controls-mobile p img{
        margin-right: -10px;
    }
    .splashscreen .column--right .years120__bottom{
        padding-top: 10px;
    }
    .splashscreen .column--right .years120__bottom img{
        max-width: 210px;
        top: -55px;
    }
    .splashscreen__loader{
        margin-top: -30px;
    }
    .splashscreen__loader--ready {
        margin-top: -20px;
    }
    .splashscreen__loader img{
        max-width: 130px;
        height: auto;
        display: inline;
        margin-left: 70px;
    }
    .splashscreen__loader--ready img {
        margin-left: 85px;
    }
    .splashscreen .column--right .years120{
        padding-top: 20px;
    }
    .splashscreen .column--right footer{
        height: 44px;
    }
    .splashscreen .column--right footer img{
        max-width: 88px;
    }
    .splashscreen-video-bg video{
        width: 152%;
        top: -50%;
        left: 0;
    }
    #controls-modal .splashscreen__controls {
        padding: 10px 15px 10px;
    }
    /* [/Splashscreen Modal] */

    /* [Gift Form] */
    #gift__form form {
        margin-top: -40px;
    }
    #gift__form form label {
        font-size: 0.45rem;
    }
    #gift__form form input[type=email] {
        height: 20px;
        font-size: 0.6rem;
    }
    #gift__form form input[type=checkbox] {
        height: 10px;
        width: 10px;
    }
    #gift__form form button {
        height: 20px;
        font-size: 0.6rem;
    }
    .email_success {
        margin-top: 12px;
    }
    .email_success span.big-text {
        font-size: 0.8rem;
    }
    /* [/Gift Form] */
}

@media screen and (min-width: 641px) and (max-width: 1000px)
{
    /* [Webview Block Modal] */
    .webview-show-tablet {
        display: none;
    }
    .webview-show-mobile {
        display: block;
    }

    #webview-block-modal .splashscreen__modal {
        max-width: 800px;
        width: 95%;
        max-height: 350px;
    }

    .splashscreen .column--right-webview {
        top: 8px;
        right: 38px;
        height: 340px;
        width: 120px;
    }
    .splashscreen .column--left-webview {
        padding: 52px 0 22px 50px;
        max-width: 297px;
    }

    .column--left-webview .webview-modal__title {
        width: 295px;
        height: 42px;
    }
    .column--left-webview .webview-modal__video {
        width: 355px;
        height: 200px;
        position: fixed;
        right: 26px;
        top: 60px;
    }

    .column--left-webview h2 {
        font-size: 1.12rem;
    }
    .column--left-webview p {
        font-size: 0.85rem;
        width: 297px;
        margin: 16px 0 35px 0;
    }

    .column--left-webview #webview__link {
        width: 256px;
        height: 30px;
        font-size: 1.38rem;
    }
    .column--left-webview #webview-modal__copy-link {
        height: 38px;
        width: 230px;
        margin: auto;
        margin-left: 50px;
        margin: -7.5px 0 0 108px;

    }
    .column--left-webview .webview-modal__small-arrows {
        display: none;
    }
    /* [/Webview Block Modal] */
}

@media screen and (max-width: 1000px) and (min-height: 1000px) and (max-height: 1199px)
{
    /* [Webview Block Modal] */
    .webview-show-mobile,
    .webview-show-tablet {
        display: none;
    }
    .webview-show-tablet-vertical {
        display: block;
    }

    #webview-block-modal .splashscreen__modal {
        max-width: 740px;
        width: 95%;
        max-height: 1000px;
    }

    .splashscreen .column--right-webview {
        top: 196px;
        right: 0px;
        height: 762px;
        width: 260px;
    }
    .splashscreen .column--left-webview {
        padding: 52px 0 140px 110px;
        max-width: unset;
    }

    .column--left-webview .webview-modal__title {
        width: 520px;
        height: 72px;
    }
    .column--left-webview .webview-modal__video {
        width: 740px;
        height: 415px;
        margin-left: -110px;
        position: inherit;
        right: unset;
        top: unset;
    }

    .column--left-webview h2 {
        font-size: 1.5rem;
        max-width: 315px;
    }
    .column--left-webview p {
        font-size: 0.9rem;
        width: 315px;
        margin: 12px 0 30px 0;
    }

    .column--left-webview .webview-modal__clip-area {
        display: block;
    }
    .column--left-webview #webview__link {
        width: 256px;
        height: 30px;
        font-size: 1.38rem;
        margin-bottom: 20px;
    }
    .column--left-webview #webview-modal__copy-link {
        height: 26px;
        width: 162px;
        margin: 0 45px 0 0;
    }
    .column--left-webview .webview-modal__small-arrows {
        display: block;
    }
    /* [/Webview Block Modal] */
}

@media screen and (max-width: 1024px)
{
    /* [Cockpit - HUD Buttons] */
    .cockpit {
        top: 25px;
        left: 30px;
    }
    .cockpit.cockpit--right {
        right: 30px;
    }

    .cockpit__item {
        height: 60px;
        width: 44px;
        margin-bottom: 5px;
    }
    .cockpit__item p {
        margin-top: -2px;
        font-size: 0.68rem;
    }

    .cockpit div:first-child {
        margin-bottom: 33px;
    }

    .cockpit__button {
        background-size: 22px;
    }
    .cockpit__button#button--books {
        margin: 8px 0 0 0;
    }
    /* [/Cockpit - HUD Buttons] */

    /* [Map Modal] */ 
    .map-modal {
        max-width: 600px;
        max-height: 300px;
    }
    .map-modal__column--info {
        width: 45%;
        border-radius: 10px;
        padding: 0 0 0 30px;
    }
    .map-modal__title {
        width: 160px;
        height: 60px;
        margin-left: 30px;
        margin-top: 25px;
    }

    .map-modal__column--info .map-modal-info__slogan {
        font-size: 1.2rem;
        margin-bottom: 24px;
        margin-top: 90px;
        line-height: 125%;
    }
    .map-modal__column--info p:not(.map-modal-info__link) {
        font-size: 1.1rem;
        font-size: 0.6rem;
        line-height: 15px;
    }
    .map-modal__column--info .map-modal-info__link {
        font-size: 1rem;
        line-height: 24px;
    }
    #map-modal--ionic .map-modal-info__slogan {
        margin-bottom: 10px;
    }

    .map-modal__background {
        border-radius: 10px;
    }

    .map-modal__big-arrow {
        height: 98px;
        width: 53px;
        bottom: 10px;
    }
    .map-modal__small-arrows-grid {
        height: 30px;
        width: 154px;
        bottom: 43px;
        left: 67px;
        grid-template-columns: 8.6px 8.6px 8.6px 8.6px 8.6px 8.6px 8.6px 8.6px;
        grid-template-rows: 10.5px 10.5px;
        row-gap: 8.5px;
        column-gap: 12px;
    }
    /* [/Map Modal] */ 


    /* [Placeholder Exit Button] */
    .exit-button {
        height: 30px;
        width: 30px;
        font-size: 0.75rem;
    }
    /* [/Placeholder Exit Button] */

    /* [Share Modal] */
    .share-modal {
        max-width: 400px;
        max-height: 300px;
    }
    .share-modal__options {
        display: flex;
    }
    .share-modal__banner {
        max-height: 139px;
    }
    .share-modal__info .share-modal__title {
        width: 158px;
        height: 22.5px;
        margin-top: 21px;
        margin-bottom: 23px;
    }

    .share-modal__options {
        margin: auto;
    }
    .share-modal__info p {
        font-size: 0.6rem;
        margin: 0;
        margin-top: 5px;
        margin-right: 23px;
    }
    .share-modal__icons-area {
        margin: 0;
    }

    .share-modal__icons-area div:first-child {
        margin-right: 30px;
    }
    .share-modal__icons-area div:last-child {
        margin-left: 30px;
    }
    .share__icon {
        height: 21px;
        width: 21px;
    }

    .share-modal__link-area {
        margin-top: 18px;
    }
    .share-modal__arrow {
        height: 26px;
        width: 18px;
        margin-right: 30px;
    }
    .share-modal__copy-link {
        height: 17px;
        width: 67px;
        margin-left: 26px;
    }
    .share-modal #share__link {
        width: 150px;
        font-size: 0.6rem;
    }
    /* [/Share Modal] */
}

@media screen and (max-width: 741px) and (max-height: 513px)
{
    /* [Webview Block Modal] */
    .webview-show-mobile {
        display: none;
    }
    .webview-show-small-mobile {
        display: block;
    }

    .splashscreen .column--left-webview {
        max-width: 250px;
    }

    .column--left-webview .webview-modal__title {
        width: 250px;
        height: 35px;
    }
    .column--left-webview h2 {
        font-size: 0.96rem;
    }
    .column--left-webview p {
        font-size: 0.72rem;
        width: 250px;
        margin: 16px 0 50px 0;
    }
    .column--left-webview .webview-modal__video {
        width: 290px;
        height: 163px;
    }

    .column--left-webview #webview__link {
        width: 220px;
        height: 30px;
        font-size: 1.2rem;
    }
    .column--left-webview #webview-modal__copy-link {
        height: 30px;
        width: 178px;
        margin: auto;
        margin-left: 50px;
        margin: 0 0 0 42px;

    }
    /* [Webview Block Modal] */
}

@media screen and (max-width: 640px)
{
    .mb-2{
        margin-bottom: 5px;
    }

    #gift__form form label{
        font-size: 0.45rem;
    }
    #gift__form form input[type=checkbox] {
        height: 10px;
        width: 10px;
    }

    .mobile-text{
        font-size: 12px !important;
    }

    /* [Splashscreen Modal] */
    .show-mobile{
        display: block;
    }

    .mobile-logo img{
        max-width: none !important;
    }

    .mobile-ident:before { content: "\00a0 "; }

    .splashscreen .splashscreen__modal {
        max-width: 320px;
        max-height: 100%;
        height: auto;
    }
    .splashscreen .splashscreen__modal .splashscreen__row {
        display: block;
    }
    .splashscreen .splashscreen__modal .splashscreen__row .splashscreen__column{
        width: calc( 100% - 40px);
        padding: 20px 20px 0;
    }
    .splashscreen .column--left{
        padding: 20px;
    }
    .splashscreen img{
        max-width: 100%;
        height: auto;
    }
    .splashscreen .column--left .modal__content h3{
        font-size: 14px;
        padding: 0;
        margin-bottom: 10px;
    }
    .splashscreen .column--left .modal__content h3.smaller-title{
        padding: 0;
    }
    .splashscreen .column--right .years120__top img {
        max-width: 90px;
        height: auto;
        left: -25px;
        top: -5px;
    }
    .splashscreen .column--right .years120__top{
        padding-left: 75px;
        margin-bottom: 60px;
    }
    .splashscreen .column--right .years120__top h2{
        font-size: 16px;
        margin-bottom: 10px;
    }
    .splashscreen .column--right .years120__top h2::after{
        margin: 0;
    }
    .splashscreen .column--right .years120__top p{
        font-size: 10px;
    }
    .splashscreen .column--left .splashscreen__controls h2{
        font-size: 14px;
        margin-bottom: 10px;
    }
    .splashscreen__controls {
        margin: 0;
        padding: 10px 15px 25px;
    }
    .splashscreen__controls p{
        font-size: 12px;
    }
    .splashscreen__controls-mobile p img{
        margin-right: -10px;
    }
    .splashscreen .column--right .years120__bottom{
        padding-top: 10px;
        margin: 0 -20px;
    }
    .splashscreen .column--right .years120__bottom img{
        max-width: 210px;
        top: -55px;
    }
    .splashscreen__loader{
        margin-top: -30px;
    }
    .splashscreen__loader img{
        max-width: 130px;
        height: auto;
        display: inline;
        margin-left: 70px;
    }
    .splashscreen__loader--ready img {
        margin-top: 10px;
    }
    .splashscreen .column--right .years120{
        padding-top: 20px;
    }
    .splashscreen .column--right footer{
        height: 44px;
    }
    .splashscreen .column--right footer img{
        max-width: 88px;
    }
    .splashscreen-video-bg video{
        width: 100%;
        top: 0;
        left: 0;
        padding: 0;
        height: auto;
    }
    /* [/Splashscreen Modal] */

    /* [Webview Block Modal] */
    .webview-show-mobile,
    .webview-show-small-mobile,
    .webview-show-tablet {
        display: none;
    }
    .webview-show-mobile-vertical {
        display: block;
    }

    #webview-block-modal .splashscreen__modal {
        max-width: 360px;
        width: 95%;
        max-height: 800px;
    }

    .splashscreen .column--right-webview {
        top: 78px;
        right: 0px;
        height: 430px;
        width: 150px;
    }
    .splashscreen .column--left-webview {
        padding: 30px 0 38px 32px;
        max-width: unset;
    }

    .column--left-webview .webview-modal__title {
        width: 295px;
        height: 42px;
    }
    .column--left-webview .webview-modal__video {
        width: 375px;
        height: 210px;
        margin-left: -37px;
        position: inherit;
        right: unset;
        top: unset;
    }

    .column--left-webview h2 {
        font-size: 0.92rem;
        max-width: 200px;
    }
    .column--left-webview p {
        font-size: 0.69rem;
        width: 182px;
        margin: 12px 0 44px 0;
    }

    .column--left-webview .webview-modal__clip-area {
        display: block;
    }
    .column--left-webview #webview__link {
        width: 256px;
        height: 30px;
        margin-left: 20px;
        font-size: 1.38rem;
        margin-bottom: 20px;
    }
    .column--left-webview #webview-modal__copy-link {
        height: 34px;
        width: 206px;
        margin: 0 45px 0 45px;
    }
    .column--left-webview .webview-modal__small-arrows {
        display: none;
    }
    /* [/Webview Block Modal] */
}

@media screen and ((max-width: 480px) or (max-height: 480px))
{
    /* [Cockpit - HUD Buttons] */
    .cockpit {
        top: 25px;
        left: 30px;
    }
    .cockpit.cockpit--right {
        right: 30px;
    }

    .cockpit__item {
        height: 60px;
        width: 44px;
        margin-bottom: 5px;
    }
    .cockpit__item p {
        margin-top: -2px;
        font-size: 0.68rem;
    }

    .cockpit div:first-child {
        margin-bottom: 33px;
    }

    .cockpit__button {
        background-size: 22px;
    }
    .cockpit__button#button--books {
        margin: 8px 0 0 0;
    }
    /* [/Cockpit - HUD Buttons] */


    /* [Informative Tag] */
    .informative-tag {
        top: 25px;
        width: 190px;
        height: 70px;
        background-size: 100%;
    }
    /* [/Informative Tag] */
}

@media screen and (max-width: 480px)
{
    #gift__form form label {
        font-size: 0.65rem;
    }
    #gift__form form button {
        height: 20px;
        font-size: 0.6rem;
        margin-top: -12px;
        margin-left: -14px;
    }
    #gift__form form input[type=email] {
        height: 20px;
        font-size: 0.6rem;
    }
    #gift__form form input[type=checkbox] {
        height: 10px;
        width: 10px;
    }
    .email_success {
        margin-top: 12px;
    }
    .email_success span.big-text {
        font-size: 0.8rem;
    }

    /* [Share Modal] */
    .share-modal {
        max-width: 320px;
        max-height: 600px;
    }
    .share-modal__options {
        display: block;
    }
    .share-modal__banner {
        max-height: 238px;
        background-image: url('../static/interface/images/share-modal-banner-mobile.svg');
    }
    .share-modal__info .share-modal__title {
        width: 202px;
        height: 29px;
        margin-top: 40px;
        margin-bottom: 34px;
    }

    .share-modal__info p {
        font-size: 1rem;
        margin: auto;
        margin-bottom: 24px;
    }
    .share-modal__icons-area {
        margin: auto;
    }

    .share-modal__icons-area div:first-child {
        margin-right: 48px;
    }
    .share-modal__icons-area div:last-child {
        margin-left: 48px;
    }
    .share__icon {
        height: 34px;
        width: 34px;
    }

    .share-modal__link-area {
        display: block;
        margin-top: 39px;
    }
    .share-modal__copy-link {
        background-image: url('../static/interface/images/copy-link-button-mobile.svg');
        height: 40px;
        width: 198px;
        margin: auto;
        margin-top: 24px;
    }
    .share-modal #share__link {
        width: 150px;
        font-size: 0.6rem;
    }
    /* [/Share Modal] */

    /* [Map Modal] */ 
    .map-modal {
        max-width: 320px;
        max-height: 600px;
        flex-direction: column-reverse;
    }
    .map-modal__column--info {
        max-width: 290px;
        width: 100%;
        height: 45%;
        border-radius: 20px;
    }
    .map-modal__column--map {
        width: 100%;
    }
    .map-modal__background {
        width: 100%;
        height: 60%;
    }
    .map-modal__title {
        position: fixed;
        top: 57%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        height: 90px;
        margin: 0;
    }
    .map-modal__column--info .map-modal-info__slogan {
        font-size: 1.25rem;
        margin-top: 58px;
    }
    .map-modal__column--map {
        overflow: hidden;
        height: 55%;
    }
    .map-modal__map {
        position: relative;
        width: 430px;
        height: 426px;
        margin-left: 0;
    }
    .map-modal__map.map--institutional {
        background-image: url('../static/interface/images/maps/institutional-map--no-tag.svg');
        top: -42%;
        left: -22%;
    }
    .map-modal__map.map--ionic {
        background-image: url('../static/interface/images/maps/ionic-map--no-tag.svg');
        top: 0;
        left: -22%;
    }
    .map-modal__map.map--literature {
        background-image: url('../static/interface/images/maps/literature-map--no-tag.svg');
        top: -35%;
        left: 18%;
    }
    .map-modal__map.map--sde {
        background-image: url('../static/interface/images/maps/sde-map--no-tag.svg');
        top: -20%;
        left: -60%;
    }
    /* [/Map Modal] */ 
}